@import '../../scss/theme-bootstrap';

@if $cr22 == true {
  .mpp-container,
  #product-results,
  .product-grid__content,
  .tabbed-products-block__content-item,
  .content-block-product-card,
  .content_block-product-card {
    .product-brief {
      margin: 0;
      text-align: $ldirection;
      &__image-container {
        margin-bottom: 40px;
        @include breakpoint($medium-up) {
          margin-bottom: 20px;
        }
        .product-brief {
          &__flag {
            display: inline-block;
            text-align: $ldirection;
            height: auto;
            &--preorder,
            &--text,
            &--subheader {
              @include typography-link-text;
              color: $color-gray-op65;
              margin: 0;
              padding: 0;
              border: none;
            }
          }
          &__image-wrapper,
          &__image-link {
            display: block;
            width: 100%;
          }
          &__image {
            width: 100%;
            height: auto;
            max-height: none;
            min-height: auto;
            &-wrapper {
              min-height: calc(100vw - 40px);
              @include breakpoint($medium-up) {
                min-height: auto;
                padding-bottom: 100%;
                .product-brief__image-link {
                  position: absolute;
                }
              }
            }
          }
        }
        > .product-brief__image-link,
        .product-brief__flag-link {
          display: inline-block;
          margin-bottom: 5px;
          min-height: 19px;
        }
        .product-brief__flag-link {
          @if $cr24 == true {
            position: relative;
          }
        }
      }
      &__product-info-wrapper {
        max-width: 100%;
      }
      &__shades {
        max-width: none;
        padding: 0;
        margin-bottom: 16px;
        .product-brief-shades {
          &__header,
          &__header-wrapper {
            margin-bottom: 16px;
            padding-#{$ldirection}: 0;
          }
          &__personalization {
            text-align: $ldirection;
            display: none;
            &.active {
              display: block;
            }
            .ff-personalization__hex {
              margin-#{$ldirection}: 0;
            }
            .ff-personalization__msg {
              @include typography-link-text;
              color: $color-core-black;
            }
          }
          &__title {
            text-align: $ldirection;
          }
          &__name,
          &__badge {
            @include typography-link-text;
            display: inline-block;
            color: $color-gray-op65;
            text-transform: none;
          }
          &__grid {
            max-width: none;
            padding: 0 25px;
            .slick-prev {
              #{$ldirection}: 0;
            }
            .slick-next {
              #{$rdirection}: 0;
            }
            &.no-arrows {
              padding: 0;
              .slick-track {
                margin: 0;
              }
            }
          }
        }
      }
      &__header {
        @include typography-link-text;
        color: $color-core-black;
        text-align: $ldirection;
        margin-bottom: 4px;
        text-transform: none;
      }
      &__abstract {
        text-align: $ldirection;
        max-width: none;
        margin-bottom: 20px;
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
        .product-brief {
          &__short-desc {
            @include typography-link-text;
            color: $color-gray-op65;
            text-align: $ldirection;
            margin-bottom: 20px;
          }
          &__price {
            text-align: $ldirection;
            color: $color-gray-op65;
            .price {
              color: $color-gray-op65;
            }
            &.discount_price {
              .price {
                @include bbtext-bold;
                color: $color-core-red;
              }
            }
          }
        }
      }
      &__price {
        @include typography-link-text;
        text-align: $ldirection;
        margin-bottom: 20px;
        .price {
          color: $color-core-black;
        }
        &.discount_price {
          .price {
            @include bbtext-bold;
            color: $color-core-red;
          }
          .old_price {
            color: $color-gray-op65;
          }
        }
      }
      &__headline-review-link {
        margin-bottom: 16px;
        position: absolute;
        top: 0;
        #{$rdirection}: 0;
        .product-full__review-snippet {
          .pr-category-snippet__rating {
            padding: 0;
          }
          .pr-rating-stars {
            .pr-star-v4 {
              display: none;
              &:first-of-type {
                display: block;
              }
            }
          }
          .pr-snippet-rating-decimal {
            @include typography-link-text;
            display: inline-block;
            padding: 0 4px !important;
            font-size: 15px !important;
            color: $color-core-black;
            border: none;
          }
          .pr-category-snippet__total {
            @include typography-link-text;
            display: inline-block;
            padding: 0;
            color: $color-core-black;
          }
          .pr-no-reviews {
            display: none;
          }
        }
      }
      &__bottom {
        max-width: none;
        text-align: $ldirection;
        .product-brief {
          &__add-to-bag {
            width: 100%;
            text-align: $ldirection;
            .product__button {
              @include cta-primary;
              width: 100%;
              max-width: none;
              min-width: auto;
              height: auto;
              @include breakpoint($medium-up) {
                padding: 20px 10px;
              }
              &.button--disabled {
                @include cta-primary-gray;
                cursor: not-allowed;
                @include breakpoint($medium-up) {
                  padding: 20px 10px;
                }
              }
            }
            .product__notify-me {
              display: block;
              width: 100%;
              max-width: none;
              .product__button {
                height: auto;
              }
            }
          }
          &__vto {
            & ~ .product-brief__add-to-bag {
              width: 50%;
            }
            & ~ .product-brief__foundation-vto ~ .product-brief__add-to-bag {
              width: 50%;
            }
          }
          &__foundation-vto {
            & ~ .product-brief__add-to-bag {
              width: 50%;
            }
            @include breakpoint($medium-up) {
              & ~ .product-brief__add-to-bag {
                width: 100%;
              }
            }
          }
        }
      }
      .product-brief__sized {
        &-title {
          @include breakpoint($medium-up) {
            margin-bottom: 16px;
          }
        }
        &-buttons {
          min-height: auto;
          @include breakpoint($medium-up) {
            min-height: 88px;
          }
          .sku-list__list {
            text-align: $ldirection;
            padding: 15px 0;
            @include breakpoint($medium-up) {
              padding: 30px 0;
            }
          }
          .sku-list__item {
            @include swap_direction(margin, 0 20px 20px 0);
          }
        }
      }
      .product__inventory-status {
        @include typography-annotation-text;
        text-align: $ldirection;
        li {
          padding-bottom: 20px;
        }
      }
      &__shades,
      &__abstract,
      &__bottom {
        .tabbed-products-block & {
          max-width: none;
        }
      }
      &__abstract-item {
        display: inline;
        & * {
          display: inline;
          margin-bottom: 0;
        }
      }
      &__image-wrapper {
        .slick-dots {
          position: absolute;
          bottom: auto;
          margin-top: 10px;
          text-align: #{$ldirection};
          @include breakpoint($medium-up) {
            display: none;
          }
          li {
            width: auto;
            height: auto;
            margin: 0;
          }
          button {
            @include swap_direction(margin, 1px 8px 0 0);
            display: inline-block;
            width: 12px;
            height: 1px;
            background-color: $color-gray-op65;
            padding: 0;
            &:before {
              display: none;
            }
          }
          .slick-active {
            button {
              height: 2px;
              margin-top: 0;
              background-color: $color-core-black;
            }
          }
        }
      }
      &.product-brief--display-image-badge {
        .product-brief__headline-review-link {
          position: initial;
        }
      }
    }
    .p-w-r .pr-snippet-stars {
      background: transparent;
    }
    &.dark-background {
      .product-brief {
        .product-brief__abstract {
          .product-brief__price {
            .price {
              color: $color-white-op50;
            }
            &.discount_price {
              .price {
                color: $color-white;
              }
              .old_price {
                color: $color-white-op50;
              }
            }
          }
        }
        &__vto-cta,
        &__foundation-vto-cta {
          @include cta-primary-white;
          &:hover {
            background-color: $color-white;
            color: $color-core-black;
          }
          .icon--camera {
            fill: $color-core-black;
          }
        }
        &__header {
          color: $color-white;
        }
        &__shades,
        &__sizes {
          &-count {
            color: $color-white-op50;
            &:after {
              color: $color-white-op50;
            }
          }
        }
        &__abstract-item {
          color: $color-white-op50;
          &:after {
            color: $color-white-op50;
          }
        }
        &-shades {
          .slick-next:before,
          .slick-prev:before {
            color: $color-white;
          }
          &__name,
          &__badge {
            color: $color-white;
          }
          &__shade {
            border: 1px solid $color-core-black;
            box-shadow: $color-core-black 0 0 1px 1px;
            &.selected,
            &:hover {
              border: 1px solid $color-core-black;
              box-shadow: $color-white 0 0 1px 2px;
            }
          }
          &__personalization {
            .ff-personalization__msg {
              color: $color-white;
            }
          }
        }
        &__short-desc,
        &__flag--subheader,
        &__price {
          color: $color-white-op50;
        }
        &__bottom {
          .product__button {
            @include cta-primary-white;
            &:hover {
              background-color: $color-white;
              color: $color-core-black;
            }
          }
          .product__inventory-status {
            color: $color-white-op50;
          }
        }
        &[data-rating]:not([data-rating='0.0'])
          .product-brief__image-container
          .product-brief__flag
          .product-brief__flag--subheader {
          border-color: $color-white-op50;
        }
        &__image-wrapper {
          .slick-dots {
            button {
              background-color: $color-white-op50;
            }
            .slick-active {
              button {
                background-color: $color-white;
              }
            }
          }
        }
        &__shade,
        &__sized {
          &-name {
            color: $color-white;
            &::before {
              filter: brightness(1) invert(1);
            }
            &:only-child {
              color: $color-white;
            }
          }
          &-arrow {
            filter: brightness(1) invert(1);
          }
        }
        &__sized-buttons {
          .sku-list__list {
            .sku-list__item {
              &:not(:only-child) {
                .sku-list__button {
                  &.button--active {
                    color: $color-white;
                    &:hover {
                      color: $color-white;
                    }
                  }
                }
              }
              .sku-list__button {
                color: $color-white-op50;
                &::before {
                  filter: brightness(1) invert(1);
                }
                &.button--active {
                  color: $color-white-op50;
                  &:hover {
                    color: $color-white-op50;
                  }
                }
              }
            }
          }
        }
        &__size {
          color: $color-white-op50;
        }
      }
      .p-w-r .pr-snippet-stars {
        filter: brightness(1) invert(1);
      }
      .product-full__review-snippet .pr-category-snippet__total {
        color: $color-white;
      }
      .product-grid__wrapper::before {
        display: none;
      }
    }
  }
}
